<template>
  <KeepAlive>
    <b-card-code>
      <validation-observer ref="simpleRules">
        <b-form  style="" @submit.prevent>
          <b-row>
            <!--  password -->
            <b-col cols="8">
              <b-form-group label="كلمة المرور" label-for="v-password">
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required|password"
                >
                  <b-form-input
                    id="v-password"
                    v-model="password"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="كلمة المرور"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--  password -->
            <b-col cols="8">
              <b-form-group label="تأكيد كلمة المرور" label-for="v-password">
                <validation-provider
                  #default="{ errors }"
                  name="تأكيد كلمة المرور"
                  rules="required"
                   vid="password"
                >
                  <b-form-input
                    id="v-password"
                    v-model="confirmPassword"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="تأكيد كلمة المرور"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                   <small v-if="password !== confirmPassword" class="text-danger">كلمة المرور ليست متطابقة</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            
            <!-- submit and reset -->
            <b-col cols="6">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="validationForm"
              >
                تأكيد
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="back"
                variant="primary"
                style="margin-right: 15px"
                @click="this.back"
              >
                رجوع
              </b-button>
            </b-col>
          </b-row>
        </b-form>

        
      </validation-observer>
    </b-card-code>
  </KeepAlive>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BFormCheckboxGroup,
  BFormRadio,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import store from "@/store/index";
import router from "@/router";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckboxGroup,
    BFormRadio,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  },

  directives: {
    Ripple,
  },
  props: ["id"],
  data() {
    return {
      // ? Default locale for VeeValidate is 'en'
      locale: "ar",
      // for validation
      required,
      password: null,
      confirmPassword:null,
      
    };
  },
  mounted() {
    // switch to arabic in validation
    localize(this.locale);
  },
  methods: {
    validationForm() {
        this.$refs.simpleRules.validate().then((success) => {
          if (success&& this.password === this.confirmPassword) {
            this.check = false
            // eslint-disable-next-line
            this.ResetPassword();
          }
          
        });
      
    },

    async ResetPassword() {
      const payload = {
        userId:this.id,
        NewPassword: this.password,
        ConfirmPassword: this.confirmPassword,
      };
      try {
        const response = await this.$store.dispatch("ResetPassword", payload);

        if ((response.status = 200)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "تم تعديل كلمة المرور بنجاح",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$router.push({
            name: "admins",
          });
        }
        
      } catch (err) {
        if (err.response) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data,
              icon: "XIcon",
              variant: "warning",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "فشل الاتصال بالسيرفر يرجى المحاولة لاحقا",
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
      }
    },
   
    back() {
      router.push("/admins");
    },
  },
};
</script>
